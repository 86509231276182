<template>
  <div class="page-main">
    <!-- 表格操作条 -->
    <vxe-toolbar ref="refToolbar" size="mini" import export print custom :loading="loading">
      <template #buttons>
        <el-button type="success" size="mini" @click="itemAdd"> 新增客户 </el-button>
        <el-button type="danger" size="mini" @click="batchDelete">删除客户</el-button>
        <el-button type="primary" size="mini" @click="batchReplaceSalesMan">
          批量替换业务员
        </el-button>
        <el-button type="primary" size="mini" @click="batchBan">激活/禁用客户</el-button>
      </template>
      <template #tools>
        <vxe-button
          type="text"
          title="模板下载"
          class="vxe-button type--button el-icon-notebook-2 is--circle"
          @click="downloadModule"
        />
      </template>
    </vxe-toolbar>

    <!-- 表格 -->
    <vxe-grid
      ref="refTable"
      size="mini"
      border
      show-overflow
      show-footer
      highlight-current-row
      :loading="loading"
      :max-height="this.$util.getViewHeight() - 150"
      :print-config="{}"
      :checkbox-config="{ range: true }"
      :import-config="{
        remote: true,
        importMethod: this.importMethod,
        modes: ['insert'],
        type: ['xlsx'],
        types: ['xlsx']
      }"
      :scroll-y="{ enabled: false }"
      :data="tableDataMain"
      :columns="tableColumn"
      :mouse-config="{ selected: false }"
      :footer-method="footerMethod"
    >
      <!-- slot_header -->
      <template #header_autocomplete="{ column }">
        <c-table-header
          :title="column.title"
          :field="column.field"
          :url="headerFilterUrl"
          @change="headerFilterChange"
        />
      </template>
      <template #header_daterange="{ column }">
        <c-table-header
          type="daterange"
          :title="column.title"
          :field="column.field"
          :url="headerFilterUrl"
          :value.sync="filterData.create_date"
          @change="headerFilterChange"
        />
      </template>

      <!-- slot_default -->
      <template #default_img_list="{ row }">
        <div v-for="(imgs, index) in row.photo_list" :key="index">
          <span class="el-upload-list__item-actions">
            <span class="el-upload-list__item-preview" @click="itemImgPreview(imgs.url)">
              <img style="height: 30px" class="el-upload-list__item-thumbnail" :src="imgs.url" />
            </span>
          </span>
        </div>
        <vxe-modal
          v-model="dialogVisible"
          width="500px"
          title="资料图片"
          :esc-closable="false"
          :mask-closable="true"
          :lock-view="true"
          :mask="false"
          resize
          :draggable="true"
        >
          <img width="100%" :src="dialogImageUrl" alt="" />
        </vxe-modal>
      </template>
      <template #default_operate="{ row }">
        <template>
          <vxe-button
            title="编辑"
            icon="el-icon-edit-outline"
            size="mini"
            circle
            @click="itemEdit(row.id)"
          />
        </template>
        <vxe-button
          title="删除"
          icon="el-icon-delete"
          size="mini"
          circle
          @click="itemDelete(row)"
        />
      </template>

      <!-- slot_pager -->
      <template #pager>
        <div></div>
        <vxe-pager v-bind="pageOptions" @page-change="pageChange" />
      </template>
    </vxe-grid>

    <b-popup-customer
      v-if="isShowPopupCustomer"
      :show.sync="isShowPopupCustomer"
      :id="activeId"
      @success="getListData"
    />

    <!-- 导入弹窗 -->
    <PopupImport
      v-if="isShowPopupImport"
      :show.sync="isShowPopupImport"
      :isError="isError"
      :uuid="uuid"
      :list="importTableData"
      @success="getListData"
    />

    <!-- 批量替换弹窗 -->
    <PopupBatchReplace
      v-if="isShowPopupBatchReplace"
      :show.sync="isShowPopupBatchReplace"
      :list="checkboxListData"
      @success="getListData"
    />
  </div>
</template>

<script>
import PopupImport from './popup-import'
import PopupBatchReplace from './popup-batch-replace'
export default {
  components: { PopupImport, PopupBatchReplace },
  data() {
    return {
      loading: true,

      remvoeIds: [],
      banIds: [],

      filterData: {
        create_date: [this.$util.getDate().yearDay, this.$util.getDate().today]
      },
      sortData: { create_date: 'desc' },
      headerFilterUrl: '/admin/customers/searchHeard',
      tableColumn: [
        {
          fixed: 'left',
          type: 'checkbox',
          width: 40
        },
        {
          field: 'id',
          width: 80,
          title: '客户编号',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'create_date',
          width: 180,
          title: '创建日期',
          slots: { header: 'header_daterange' }
        },
        // {
        //   field: 'suo_shu_hang_ye',
        //   title: '所属行业',
        //   width: 100,
        //   slots: { header: 'header_autocomplete' }
        // },
        {
          field: 'sales_man',
          title: '业务员',
          width: 100,
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'customer_name',
          title: '客户企业名称',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'linkman',
          title: '联系人',
          width: 80,
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'gen_zong_count',
          title: '跟进次数',
          width: 60,
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'phone',
          title: '联系电话',
          width: 90,
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'state',
          title: '使用状态',
          width: 60,
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'wei_xin',
          title: '联系人微信',
          width: 80,
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'address',
          title: '客户地址',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'alibaba',
          title: '旺旺号',
          width: 80,
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'sales_amount_total',
          title: '合同总金额',
          width: 80,
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'profit_total',
          title: '总利润',
          width: 80,
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'check_count',
          title: '核算单次数',
          width: 80,
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'lai_yuan',
          title: '客户来源',
          width: 80,
          slots: { header: 'header_autocomplete' }
        },
        {
          fixed: 'right',
          title: '操作',
          width: 80,
          slots: { default: 'default_operate' }
        }
      ],
      tableDataMain: [],
      pageOptions: {
        ...this.$constant.page
      },

      isShowPopupImport: false,
      isShowPopupBatchReplace: false,
      isShowPopupCustomer: false,
      isError: '',
      activeId: null,
      uuid: '',
      importTableData: [],

      dialogImageUrl: '',
      dialogVisible: false,
      checkboxListData: []
    }
  },
  methods: {
    // 头部筛选更新
    headerFilterChange(obj) {
      this.filterData[obj.field] = obj.value
      this.searchListData()
    },
    // 搜索数据列表
    searchListData() {
      this.pageOptions.currentPage = 1
      this.getListData()
    },
    // 获取数据列表
    getListData() {
      this.loading = true
      this.$api({
        method: 'post',
        url: '/admin/customers/search',
        data: {
          currentPage: this.pageOptions.currentPage,
          pageSize: this.pageOptions.pageSize,
          sortData: this.sortData,
          filterData: this.filterData
        }
      })
        .then((res) => {
          if (res.status === 200) {
            this.tableDataMain = res.data.page.list
            this.pageOptions.pageSize = res.data.page.pageSize
            this.pageOptions.total = res.data.page.totalRow
            this.pageOptions.currentPage = res.data.page.pageNumber
          }
        })
        .catch(() => {})
        .then(() => {
          this.loading = false
        })
    },
    // 分页切换
    pageChange({ currentPage, pageSize }) {
      this.pageOptions.currentPage = currentPage
      this.pageOptions.pageSize = pageSize
      this.getListData()
    },
    // 表格底部
    footerMethod({ columns, data }) {
      return [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) return '合计'
          if (['sales_amount_total', 'profit_total'].includes(column.field)) {
            return this.sumNum(data, column.field)
          }
        })
      ]
    },
    sumNum(list, field) {
      let listCount = list.map((item) => {
        return item[field]
      })
      return this.$XEUtils.sum(listCount)
    },
    itemAdd() {
      this.activeId = null
      this.isShowPopupCustomer = true
    },
    itemEdit(id) {
      this.activeId = id
      this.isShowPopupCustomer = true
    },
    // 项-删除
    itemDelete(row) {
      this.$XModal.confirm('您确定要删除该数据?').then((type) => {
        if (type === 'confirm') {
          // 传送删除动作
          this.$api({
            method: 'post',
            url: '/admin/customers/deleteVue',
            params: {
              id: row.id
            }
          }).then((res) => {
            if (res.status === 200 && res.data.state === 'ok') {
              this.$message.success('删除成功')
              this.getListData()
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }
      })
    },
    // 项-图片预览
    itemImgPreview(url) {
      this.dialogImageUrl = url
      this.dialogVisible = true
    },
    // 批量-激活/禁用
    batchBan() {
      const $grid = this.$refs.refTable
      const selectRecords = $grid.getCheckboxRecords()
      this.banIds = []
      for (let i = 0; i < selectRecords.length; i++) {
        this.banIds.push(selectRecords[i].id)
      }
      if (selectRecords.length > 0) {
        this.$XModal
          .confirm('您确定要激活/禁用 ' + this.banIds.length + ' 个客户?')
          .then((type) => {
            if (type === 'confirm') {
              // 传送删除动作
              this.$api({
                method: 'post',
                url: '/admin/customers/banBatchVue',
                params: {
                  banIds: this.banIds
                }
              }).then((res) => {
                if (res.status === 200 && res.data.state === 'ok') {
                  this.$message.success('批量删除成功')
                  this.getListData()
                } else {
                  this.$message.error(res.data.msg)
                }
              })
            }
          })
      } else {
        this.$message.error('请先选择')
      }
    },
    // 批量-删除
    batchDelete() {
      const $grid = this.$refs.refTable
      const selectRecords = $grid.getCheckboxRecords()
      this.remvoeIds = []
      for (let i = 0; i < selectRecords.length; i++) {
        this.remvoeIds.push(selectRecords[i].id)
      }
      if (selectRecords.length > 0) {
        this.$XModal.confirm('您确定要删除 ' + this.remvoeIds.length + ' 条数据?').then((type) => {
          if (type === 'confirm') {
            // 传送删除动作
            this.$api({
              method: 'post',
              url: '/admin/customers/deleteBatchVue',
              params: {
                remvoeIds: this.remvoeIds
              }
            }).then((res) => {
              if (res.status === 200 && res.data.state === 'ok') {
                this.$message.success('批量删除成功')
                this.getListData()
              } else {
                this.$message.error(res.data.msg)
              }
            })
          }
        })
      } else {
        this.$message.error('请先选择')
      }
    },
    // 批量-替换业务员
    batchReplaceSalesMan() {
      const $grid = this.$refs.refTable
      const selectRecords = $grid.getCheckboxRecords()
      if (selectRecords.length > 0) {
        this.checkboxListData = selectRecords
        this.isShowPopupBatchReplace = true
      } else {
        this.$message.error('请先选择')
      }
    },
    // 模版下载
    downloadModule() {
      window.open('/template/Customers.xlsx', '_blank')
    },
    // 导入方法
    importMethod({ file }) {
      const formBody = new FormData()
      formBody.append('file', file)
      this.$api({
        method: 'post',
        url: '/admin/customers/importExcel',
        data: formBody
      })
        .then((res) => {
          if (res.status === 200 && res.data.state === 'ok') {
            this.isError = res.data.isError
            if (res.data.isError == true) {
              this.importTableData = res.data.customerListError
            } else {
              this.importTableData = res.data.customerList
            }

            this.uuid = res.data.uuid
            this.isShowPopupImport = true
          } else {
            this.$message.error(res.data.msg)
            return
          }
        })
        .catch(() => {
          this.$message.error('网络超时，请重试！')
        })
    }
  },
  created() {
    this.$nextTick(() => {
      // 手动将表格和工具栏进行关联
      this.$refs.refTable.connect(this.$refs.refToolbar)
      this.getListData()
    })
  }
}
</script>

<style lang="less" scoped>
.page-main {
}
</style>
